import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

class TabsTwo extends Component{
    render(){
        let 
        tab1 = "Ethereum",
        tab2 = "Avalanche",
        tab3 = "Fantom",
        tab4 = "Arbitrum";
        const { tabStyle } = this.props
        return(
            <div>
                {/* Start Tabs Area */}
                <div className="tabs-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Tabs>
                                    <TabList  className={`${tabStyle}`}>
                                        <Tab>{tab1}</Tab>
                                        <Tab>{tab2}</Tab>
                                        <Tab>{tab3}</Tab>
                                        <Tab>{tab4}</Tab>
                                    </TabList>

                                    <TabPanel>
                                        <div className="single-tab-content">
                                            <ul>
                                                <li>
                                                    <a target="_blank" rel="noopener noreferrer" href="https://etherscan.io/address/0x130E527FF9DD7e2c59b576d6553A8D0f79f824f2">Migration Contract (inactive) <span> - wMEMO to Volta</span></a>
                                                    <a target="_blank" rel="noopener noreferrer" href="https://etherscan.io/address/0x130E527FF9DD7e2c59b576d6553A8D0f79f824f2"><p className="address">0x130E527FF9DD7e2c59b576d6553A8D0f79f824f2</p></a>
                                                </li>
                                                <li>
                                                    <a target="_blank" rel="noopener noreferrer" href="https://etherscan.io/token/0x9b06F3C5de42D4623D7A2Bd940EC735103c68A76">Token Contract<span> - OFT ERC20</span></a>
                                                    <a target="_blank" rel="noopener noreferrer" href="https://etherscan.io/token/0x9b06F3C5de42D4623D7A2Bd940EC735103c68A76"><p className="address">0x9b06F3C5de42D4623D7A2Bd940EC735103c68A76</p></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                            <ul>
                                                <li>
                                                    <a target="_blank" rel="noopener noreferrer" href="https://snowtrace.io/address/0x130E527FF9DD7e2c59b576d6553A8D0f79f824f2">Migration Contract (inactive) <span> - wMEMO to Volta</span></a>
                                                    <a target="_blank" rel="noopener noreferrer" href="https://snowtrace.io/address/0x130E527FF9DD7e2c59b576d6553A8D0f79f824f2"><p className="address">0x130E527FF9DD7e2c59b576d6553A8D0f79f824f2</p></a>
                                                </li>
                                                <li>
                                                    <a target="_blank" rel="noopener noreferrer" href="https://snowtrace.io/token/0x9b06F3C5de42D4623D7A2Bd940EC735103c68A76">Token Contract<span> - OFT ERC20</span></a>
                                                    <a target="_blank" rel="noopener noreferrer" href="https://snowtrace.io/token/0x9b06F3C5de42D4623D7A2Bd940EC735103c68A76"><p className="address">0x9b06F3C5de42D4623D7A2Bd940EC735103c68A76</p></a>
                                                </li>
                                            </ul>
                                       </div>
                                    </TabPanel>



                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                                <li>
                                                    <a target="_blank" rel="noopener noreferrer" href="https://ftmscan.com/address/0x130E527FF9DD7e2c59b576d6553A8D0f79f824f2">Migration Contract (inactive) <span> - wMEMO to Volta</span></a>
                                                    <a target="_blank" rel="noopener noreferrer" href="https://ftmscan.com/address/0x130E527FF9DD7e2c59b576d6553A8D0f79f824f2"><p className="address">0x130E527FF9DD7e2c59b576d6553A8D0f79f824f2</p></a>
                                                </li>
                                                <li>
                                                    <a target="_blank" rel="noopener noreferrer" href="https://ftmscan.com/token/0x9b06F3C5de42D4623D7A2Bd940EC735103c68A76">Token Contract<span> - OFT ERC20</span></a>
                                                    <a target="_blank" rel="noopener noreferrer" href="https://ftmscan.com/token/0x9b06F3C5de42D4623D7A2Bd940EC735103c68A76"><p className="address">0x9b06F3C5de42D4623D7A2Bd940EC735103c68A76</p></a>
                                                </li>
                                           </ul>
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                                <li>
                                                    <a target="_blank" rel="noopener noreferrer" href="https://arbiscan.io/address/0x130E527FF9DD7e2c59b576d6553A8D0f79f824f2">Migration Contract (inactive) <span> - wMEMO to Volta</span></a>
                                                    <a target="_blank" rel="noopener noreferrer" href="https://arbiscan.io/address/0x130E527FF9DD7e2c59b576d6553A8D0f79f824f2"><p className="address">0x130E527FF9DD7e2c59b576d6553A8D0f79f824f2</p></a>
                                                </li>
                                                <li>
                                                    <a target="_blank" rel="noopener noreferrer" href="https://arbiscan.io/token/0x9b06F3C5de42D4623D7A2Bd940EC735103c68A76">Token Contract<span> - OFT ERC20</span></a>
                                                    <a target="_blank" rel="noopener noreferrer" href="https://arbiscan.io/token/0x9b06F3C5de42D4623D7A2Bd940EC735103c68A76"><p className="address">0x9b06F3C5de42D4623D7A2Bd940EC735103c68A76</p></a>
                                                </li>
                                           </ul>
                                       </div>
                                    </TabPanel>
                                    
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tabs Area */}
            </div>
        )
    }
}



export default TabsTwo;