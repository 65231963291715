import React, { Component } from "react";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import Slider from "../component/slider/Slider";
import ServiceHome from "../elements/service/ServiceHome";

import Helmet from "../component/common/Helmet";

class MainDemo extends Component{
    render(){
        return(
            <div className="active-dark"> 
                <Helmet pageTitle="Home" />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Slider Area   */}
                <div className="slider-wrapper">
                    <Slider />
                </div>
                {/* End Slider Area   */}

                {/* Start Service Area  */}
                <div className="service-area ptb--80 bg_image bg_image--3 bg_color--1">
                <div className="art-bottom"></div>
                   <div className="container">
                        <ServiceHome />
                   </div>
                </div>
                {/* End Service Area  */}
                <Footer />
                
            </div>
        )
    }
}
export default MainDemo;