import React, { Component } from 'react';

import { ReactComponent as Twitter } from '../../../public/assets/images/socials/x-twitter.svg';
import { ReactComponent as DeBank } from '../../../public/assets/images/socials/debank.svg';
import { ReactComponent as Reddit } from '../../../public/assets/images/socials/reddit-alien.svg';
import { ReactComponent as GitHub } from '../../../public/assets/images/socials/github.svg';
import { ReactComponent as Medium } from '../../../public/assets/images/socials/medium.svg';
import { ReactComponent as Discord } from '../../../public/assets/images/socials/discord.svg';

const SocialShare = [
  {
    Social: <Twitter className="icon" />,
    link: 'https://twitter.com/thevoltaclub/',
  },
  {
    Social: <DeBank className="icon" />,
    link: 'https://debank.com/official-account/112915/',
  },
  {
    Social: <Reddit className="icon" />,
    link: 'https://www.reddit.com/r/VoltaClub/',
  },
  {
    Social: <GitHub className="icon" />,
    link: 'https://github.com/Volta-Club/',
  },
  {
    Social: <Medium className="icon" />,
    link: 'https://medium.com/@voltaclub/',
  },
  {
    Social: <Discord className="icon" />,
    link: 'https://discord.com/invite/voltaclub',
  },
];

class Footer extends Component {
  render() {
    return (
      <React.Fragment>
        <footer className="footer-area">
          <div className="footer-wrapper">
            <div className="art-bottom"></div>
            <div className="row align-items-end row--0">
              <div className="col-lg-6">
                <div className="footer-left">
                  <div className="inner">
                    <span>In a world once known as Wonderland,</span>
                    <span>A shift, a change, a brand-new hand.</span>

                    <span>From tales of Alice, Cheshire's grin,</span>
                    <span>Now Volta rises, anew from within.</span>

                    <span>Gone the Mad Hatter, the rabbit's late spree,</span>
                    <span>In their stead, currents wild and free.</span>

                    <span>Volta's bright world, electric and vast,</span>
                    <span>Honoring the past, yet built to last.</span>

                    <span>So step in, dear traveler, without a doubt,</span>
                    <span>Discover what this new world's all about.</span>

                    <span>A realm reborn, with energy bold,</span>
                    <span>Volta's the story that's yet to be told.</span>

                    <span>Wonderland's spirit, still alive, still grand,</span>
                    <span>
                      Now pulses and thrives in the heart of Volta's land.
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="footer-right" data-black-overlay="6">
                  <div className="art-top"></div>
                  <div className="row">
                    {/* Start Single Widget  */}
                    <div className="col-lg-6 col-sm-6 col-12">
                      <div className="footer-link">
                        <h4>Links</h4>
                        <ul className="ft-link">
                          <li>
                            <a href="/">Home</a>
                          </li>
                          <li>
                            <a href="https://app.volta.club/">Enter App</a>
                          </li>
                          <li>
                            <a href="/contracts">Contracts</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* End Single Widget  */}
                    {/* Start Single Widget  */}
                    <div className="col-lg-6 col-sm-6 col-12 mt_mobile--30">
                      <div className="footer-link">
                        <h4>Contact Us</h4>
                        <ul className="ft-link">
                          <li>
                            <a href="mailto:info@volta.club">info@volta.club</a>
                          </li>
                        </ul>

                        <div className="social-share-inner">
                          <ul className="social-share social-style--2 d-flex justify-content-start liststyle mt--15">
                            {SocialShare.map((val, i) => (
                              <li key={i}>
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={`${val.link}`}
                                >
                                  {val.Social}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* End Single Widget  */}

                    <div className="col-lg-12">
                      <div className="copyright-text">
                        <p>
                          Copyright © 2023 Volta Club. All Rights Reserved.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}
export default Footer;
