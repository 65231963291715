import React, { Component } from "react";
import { Link, withRouter } from 'react-router-dom';
import { FiX , FiMenu } from "react-icons/fi";


class HeaderFive extends Component{
    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
       //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function() {
            console.log('All assets are loaded')
        })
    }
    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }
    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open');
    }
    render(){

        const { location } = this.props;
        const currentPath = location.pathname;

        var elements = document.querySelectorAll('.has-droupdown > a');
        for(var i in elements) {
            if(elements.hasOwnProperty(i)) {
                elements[i].onclick = function() {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }
        
        const { color, headerPosition } = this.props;
        const logoUrl = <img src="/assets/images/logo/logo-alt.png" alt="Volta" />;
        
        return(
            <header className={`header-area formobile-menu ${headerPosition} ${color}`}>
                
                <div className="header-wrapper" id="header-wrapper">
                <div className="art-top"></div>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-3 col-md-4 col-6">
                                <div className="header-left">
                                    <div className="logo">
                                        <a href="/">
                                            {logoUrl}
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9 col-md-8 col-6">
                                <div className="header-right justify-content-end">
                                    <nav className="mainmenunav d-lg-block">
                                        <ul className="mainmenu">
                                        <li className={currentPath === "/" ? "active" : ""}><Link to="/" >Home</Link></li>
                                        <li className={currentPath === "/contracts" ? "active" : ""}><Link to="/contracts" >Contracts</Link></li>
                                        <li><a target="_blank" rel="noopener noreferrer" href="https://dao.volta.club">DAO</a></li>
                                            <li className="has-droupdown"><Link to="#" >Buy Volta</Link>
                                                <ul className="submenu">
                                                    <li><a target="_blank" rel="noopener noreferrer" href="https://app.paraswap.io/#/0xdAC17F958D2ee523a2206206994597C13D831ec7-0x9b06f3c5de42d4623d7a2bd940ec735103c68a76/1/SELL?network=ethereum">Ethereum</a></li>
                                                    <li><a target="_blank" rel="noopener noreferrer" href="https://app.paraswap.io/#/0xc7198437980c041c805A1EDcbA50c1Ce5db95118-0x9b06f3c5de42d4623d7a2bd940ec735103c68a76/1/SELL?network=avalanche">Avalanche</a></li>
                                                    {/* <li><a target="_blank" rel="noopener noreferrer" href="#">Fantom</a></li>
                                                    <li><a target="_blank" rel="noopener noreferrer" href="#">Arbitrum</a></li> */}
                                                </ul>
                                            </li>
                                        </ul>
                                    </nav>
                                    {/* Start Humberger Menu  */}
                                    <div className="humberger-menu d-block d-lg-none pl--20">
                                        <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu /></span>
                                    </div>
                                    {/* End Humberger Menu  */}
                                    <div className="close-menu d-block d-lg-none">
                                        <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}

export default withRouter(HeaderFive);