import React from 'react';
import Helmet from "../component/common/Helmet";
import Tab from "../elements/tab/Tab";
import Header from "../component/header/Header";
import ServiceContract from "../elements/service/serviceContract";
import Footer from "../component/footer/Footer";

const Contracts = () => {
    let title = 'Contracts',
        description = 'Volta is using a ',
        link = 'LayerZero Omnichain Fungible Tokens (OFT)',
        dot = '.';
    return (
        <div className="active-dark">
            <Helmet pageTitle="Contracts" />

            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
            {/* Start Slider Area   */}

            {/* Start About Area */}
            <div id="about" className="fix">
                <div className="about-area ptb--120  bg_color--1">
                <div className="art-bottom"></div>
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/contract.svg" alt="About Images"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">{title}</h2>
                                            <p className="description">{description}<a target="_blank" rel="noopener noreferrer" href='https://layerzeroscan.com/protocol/volta-club'>{link}</a>{dot}</p>
                                        </div>
                                        <div className="row mt--30">
                                            <Tab tabStyle="tab-style--1" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End About Area */}
                {/* Start Service Area  */}
                <div className="service-area ptb--80 bg_image bg_image--3 bg_color--1">
                   <div className="container">
                        <ServiceContract />
                   </div>
                </div>
                {/* End Service Area  */}
            <Footer />
            
        </div>
    )
}

export default Contracts;
