// React Required
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

// Create Import File
import './index.scss';

// Dark Home Layout
import Home from './pages/home';
import Contracts from './pages/contracts';
import * as serviceWorker from './serviceWorker';
// eslint-disable-next-line no-unused-vars
import Snowfall from 'react-snowfall';

class Root extends Component {
  render() {
    return (
      <BrowserRouter basename={'/'}>
        {/* <Snowfall snowflakeCount={300} color="#573e3e" zIndex={9999} /> */}
        <Switch>
          <Route exact path={`${process.env.PUBLIC_URL}/`} component={Home} />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/contracts`}
            component={Contracts}
          />
          <Route
            render={() => <Redirect to={`${process.env.PUBLIC_URL}/`} />}
          />
        </Switch>
      </BrowserRouter>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById('root'));
serviceWorker.register();
